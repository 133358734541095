
import { defineComponent, computed, unref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { ICourseItem } from '@/service/course/types'
import HhXgPlayer from '@/components/video-box/src/hh-xgplayer.vue'

export default defineComponent({
  components: {
    HhXgPlayer
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const {
      params: { urlKey }
    } = route

    // 网络请求
    store.dispatch('course/getCourseDetailAction', {
      url: `/course/${urlKey}`
    })

    // 从vuex中获取数据
    const course = computed<ICourseItem>(
      () => store.getters['course/courseDetailData']
    )

    const current = computed(() => {
      const {
        params: { chapterId, lessonId }
      } = route
      console.log(
        'jjjjjjj===========course',
        unref(course).chapters[Number(chapterId) - 1].lessons[
          Number(lessonId) - 1
        ]
      )
      return unref(course).chapters[Number(chapterId) - 1].lessons[
        Number(lessonId) - 1
      ]
    })

    return {
      course,
      current
    }
  }
})
